// ============================================================================
// ApplicationsService
// -------------------
// Applications module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/ApplicationsApi'
import DefaultInterceptor from '@/plugins/axios/DefaultInterceptor'
import InvalidParentSubscriptionException from '@/exceptions/Application/InvalidParentSubscriptionException'
import MissingInformationException from '@/exceptions/Application/MissingInformationException'


const PRIVATE = {
	requests: {
		accountingFirm: {
			application: {
				parameters: {
					read: function (accountingFirmId, applicationId) {
						return API.listAccountingFirmApplicationParameters(accountingFirmId, applicationId)
					}
				}
			},
			catalogTreeStructure: {
				read: function (accountingFirmId, catalogTreeStructureId) {
					return API.findCatalogTreeStructure(accountingFirmId, catalogTreeStructureId)
				},
				catalogFolder: {
					read: function (accountingFirmId, catalogTreeStructureId, catalogFolderId, params) {
						return API.findCatalogFolder(accountingFirmId, catalogTreeStructureId, catalogFolderId, params)
					}
				},
				catalogFolders: {
					read: function (accountingFirmId, catalogTreeStructureId, params) {
						return API.getCatalogFolders(accountingFirmId, catalogTreeStructureId, params)
					}
				}
			},
			subscription: {
				create: function (accountingFirmId, applicationId, data) {
					return API.createSubscription(accountingFirmId, applicationId, data, { show_error: false }).then(result => result.data.data)
					.catch(err => {
						const errorCode = err?.response?.data?.error?.code
						const errorMessage = err?.response?.data?.error?.message
						const status = err?.response?.status
						if (status === 409 && errorCode === PRIVATE.MISSING_INFORMATION) {
							throw new MissingInformationException(errorMessage, err.response.data.error.parameters)
						}
						if (err?.response?.config) {
							err.response.config.show_error = true
						}
						return DefaultInterceptor.onResponseError(err)
					})
				},
				read: function (accountingFirmId, subscriptionId, params = {}, config = {}) {
					return API.getSubscription(accountingFirmId, subscriptionId, params, config).then(result => result.data.data)
				},
				update: function (accountingFirmId, subscriptionId, data) {
					return API.updateAccountingFirmApplication(accountingFirmId, subscriptionId, data)
				},
				delete: function (accountingFirmId, applicationId) {
					return API.deleteSubscription(accountingFirmId, applicationId)
				},
				vendors: {
					create: API.createApplicationVendors,
					read: function (accountingFirmId, applicationId, params) {
						return API.listVendors(accountingFirmId, applicationId, params).then(response => {
							if (!response.data.pagination) {
								return response.data.data
							}
							return {
								data: response.data.data,
								pagination: response.data.pagination
							}
						})
					},
					parameters: {
						read: function (accountingFirmId, subscriptionId) {
						 	return API.listApplicationVendorParameters(accountingFirmId, subscriptionId, {show_error: false})
								.catch(err => {
									const errorCode = err?.response?.data?.error?.code
									const errorMessage = err?.response?.data?.error?.message
									if (err.response.data.error.parameters && errorCode === PRIVATE.INVALID_PARENT_SUBSCRIPTION) {
										throw new InvalidParentSubscriptionException(errorMessage, err.response.data.error.parameters)
									}
									if (err?.response?.config) {
										err.response.config.show_error = true
									}
									return DefaultInterceptor.onResponseError(err)
								})
						}
					}
				},
				users: {
					create: API.createApplicationUsers
				}
			},
			subscriptions: {
				read: function (accountingFirmId, params = {}) {
					return API.listApplications(accountingFirmId, params).then(result => result.data.data)
				},

			},
			vendor: {
				application: {
					create: function (accountingFirmId, vendorId, catalogApplicationId, data, config) {
						return API.createApplicationVendor(accountingFirmId, vendorId, catalogApplicationId, data, config)
					},
					modify: function (accountingFirmId, vendorId, catalogApplicationId, subscriptionId, data, config) {
						return API.modifyApplicationVendor(accountingFirmId, vendorId, catalogApplicationId, subscriptionId, data, config)
					},
					delete: function (accountingFirmId, vendorId, catalogApplicationId, subscriptionId) {
						return API.deleteApplicationVendor(accountingFirmId, vendorId, catalogApplicationId, subscriptionId)
					}
				}
			}
		},
		application: {
			importFile: {
				read: function (accountingFirmId, applicationId) {
					return API.getImportFile(accountingFirmId, applicationId)
				}
			},
			create: function(data) {
				return API.createApplication(data).then(result => result.data.data)
			},
			update: function(applicationId, data) {
				return API.updateApplication(applicationId, data).then(result => result.data.data)
			},
			users: {
				create: function(accountingFirmId, applicationId, data, config) {
					return API.createApplicationUsers(accountingFirmId, applicationId, data, config)
				},
				importFile: {
					read: function (accountingFirmId, applicationId, params) {
						return API.getImportFile(accountingFirmId, applicationId, params)
					}
				},
			}
		},
		magicMailModels: {
			read: function () {
				return API.listMagicMailModels().then(response => {
					return response.data.data
				})
			}
		}
	},
	service: {
		accountingFirm: {
			subscription: {
				vendors: {
					create: function (accountingFirmId, parentSubscriptionId) {
						return PRIVATE.requests.accountingFirm.subscription.vendors.create(accountingFirmId, parentSubscriptionId)
					},
					import: function (accountingFirmId, parentSubscriptionId, importFile, config = {}) {
						const data = new FormData()
						data.append('import_file', importFile)
						return PRIVATE.requests.accountingFirm.subscription.vendors.create(accountingFirmId, parentSubscriptionId, config, data).catch(error => {
							if (error?.response.status == 422) {
								throw error
							} else {
								if (error?.config) {
									error.config.show_error = true
								}
								return DefaultInterceptor.onResponseError(error)
							}
						})
					}
				},
				users: {
					import: function (accountingFirmId, parentSubscriptionId, importFile, config = {}) {
						const data = new FormData()
						data.append('import_file', importFile)
						 // eslint-disable-next-line
						return PRIVATE.requests.accountingFirm.subscription.users.create(accountingFirmId, parentSubscriptionId, data, config).catch(error => {
							if (error?.response.status == 422) {
								throw error
							} else {
								if (error?.config) {
									error.config.show_error = true
								}
								return DefaultInterceptor.onResponseError(error)
							}
						})
					}
				}
			}
		}
	},
	INVALID_PARENT_SUBSCRIPTION: 'application-vendor.invalid-parent-subscription',
	MISSING_INFORMATION: 'accounting-firm-application.missing-information'
}

// -------
// Exports
// -------
export default {
	updateAccountingFirmApplication: PRIVATE.requests.accountingFirm.subscription.update,

	listAccountingFirmApplicationParameters: PRIVATE.requests.accountingFirm.application.parameters.read,
	listApplicationVendorParameters: PRIVATE.requests.accountingFirm.subscription.vendors.parameters.read,

	findCatalogFolder: PRIVATE.requests.accountingFirm.catalogTreeStructure.catalogFolder.read,
	getCatalogFolders: PRIVATE.requests.accountingFirm.catalogTreeStructure.catalogFolders.read,

	findCatalogTreeStructure: PRIVATE.requests.accountingFirm.catalogTreeStructure.read,

	downloadImportFile: function (accountingFirmId, applicationId, fileName = 'import', params = {}) {
		let request = PRIVATE.requests.application.importFile
		if (params?.template == 'users') {
			request = PRIVATE.requests.application.users.importFile
		}
		return request.read(accountingFirmId, applicationId, params).then(file => {
			const objectURL = URL.createObjectURL(file)
			const link = document.createElement('a')
			document.body.appendChild(link)
			link.href = objectURL
			link.download = `${fileName}.csv`
			link.click()
			URL.revokeObjectURL(objectURL)
		})
	},
	getApplicationSubscription: function (accountingFirmId, applicationId, params = {}, config = {}) {
		return PRIVATE.requests.accountingFirm.subscription.read(accountingFirmId, applicationId, params, config)
	},
	listApplications: function (accountingFirmId) {
		return PRIVATE.requests.accountingFirm.subscriptions.read(accountingFirmId, {
			used: false
		})
	},

	listMagicMailModels: PRIVATE.requests.magicMailModels.read,

	createSubscription: PRIVATE.requests.accountingFirm.subscription.create,
	listSubscriptions: PRIVATE.requests.accountingFirm.subscriptions.read,
	deleteSubscription: PRIVATE.requests.accountingFirm.subscription.delete,

	listVendors: PRIVATE.requests.accountingFirm.subscription.vendors.read,
	setUpApplication: function (accountingFirmId, vendorId, applicationId, subscriptionId, applicationData = {}) {
		let result = Promise.resolve()
		if (subscriptionId) {
			result = PRIVATE.requests.accountingFirm.vendor.application.modify(accountingFirmId, vendorId, applicationId, subscriptionId, applicationData).then(response => {
				return response.data.data
			})
		} else {
			result = PRIVATE.requests.accountingFirm.vendor.application.create(accountingFirmId, vendorId, applicationId, applicationData).then(response => {
				return response.data.data
			})
		}

		return result
	},
	createApplicationVendors: PRIVATE.service.accountingFirm.subscription.vendors.create,
	importApplicationVendors: PRIVATE.service.accountingFirm.subscription.vendors.import,
	importApplicationUsers: PRIVATE.service.accountingFirm.subscription.users.import,
	unsetApplication: function (accountingFirmId, vendorId, catalogApplicationId, subscriptionId) {
		return PRIVATE.requests.accountingFirm.vendor.application.delete(accountingFirmId, vendorId, catalogApplicationId, subscriptionId)
	},

	checkBeeyeToken: function (accounting_firm_id, endpoint, token) {
		return API.checkBeeyeToken(accounting_firm_id, endpoint, token).then(res => res.data.data)
	},
	getBeeyeDirections: function (accounting_firm_id, endpoint, token) {
		return API.getBeeyeDirections(accounting_firm_id, endpoint, token).then(res => res.data.data)
	},
	createApplication: function(data) {
		return PRIVATE.requests.application.create(data)
	},
	createApplicationUsers: function(accountingFirmId, applicationId, data) {
		return PRIVATE.requests.application.users.create(accountingFirmId, applicationId, data)
	},
	updateApplication: function(applicationId, data) {
		return PRIVATE.requests.application.update(applicationId, data)
	}
}
